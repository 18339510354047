import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilter,
  filter,
  deleteFilter,
  getProductsNextPage,
  filterAndKeepAll,
  clearAllFilters,
} from "../actions/products-actions/actions";
import { Filter } from "../components/Filter/Filter";
import { StandardDraggableProductCard } from "../components/ProductCard/StandardDraggableProductCard";
import { Link } from "react-router-dom";
import { handleLeftDrawer } from "../actions/actionCreators";
import { Drawer, Card } from "antd";
import { useParams, useLocation } from "react-router-dom";
import "../sass/ProductsPage.scss";
import { Spinner } from "../components/Spinner";
import backArrow from "../img/backArrow.png";
import { history } from "../index";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { addProducttoFolder } from "../actions/folders-actions/actions";
import { FilterOutlined, ClearOutlined } from "@ant-design/icons";
import Head from "../components/Head";
import ExampleProductCard from "../components/ProductCard/ExampleProductCard";
import placeholder_jeans from "../img/placeholder_jeans.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import axiosInstance from "../config/axios-instance";
import { v4 as uuidv4 } from "uuid";

interface Props {}
const { Meta } = Card;

export const Products = (props: Props) => {
  const location = useLocation();
  const params: any = useParams();
  const [min_price_allowed, setMin_price_allowed] = useState(1);
  const [max_price_allowed, setMax_price_allowed] = useState(1000);
  const [min_price, setMin_price] = useState(1);
  const [max_price, setMax_price] = useState(1000);
  const [min_priceBefore, setMin_priceBefore] = useState(1);
  const [max_priceBefore, setMax_priceBefore] = useState(1000);
  const [min_elasticity, setMin_elasticity] = useState(1);
  const [max_elasticity, setMax_elasticity] = useState(1000);
  const [min_elasticity_before, setMin_elasticity_before] = useState(1);
  const [max_elasticity_before, setMax_elasticity_before] = useState(1000);
  const [min_elasticity_allowed, setMin_elasticity_allowed] = useState(1);
  const [max_elasticity_allowed, setMax_elasticity_allowed] = useState(1000);

  const [min_weight, setMin_weight] = useState(1);
  const [max_weight, setMax_weight] = useState(1000);
  const [min_weight_before, setMin_weight_before] = useState(1);
  const [max_weight_before, setMax_weight_before] = useState(1000);
  const [min_weight_allowed, setMin_weight_allowed] = useState(1);
  const [max_weight_allowed, setMax_weight_allowed] = useState(1000);
  const [forYouCompany, setForYouCompany] = useState<any>(null);

  const [count, setcount] = useState(0);
  const [queries, setQueries] = useState<Array<string>>([
    `fabric_types=${params.fabric}`,
    `categories=${params.categoryId}`,
    `genders=${params.genderId}`,
  ]);
  const [width, setWidth] = useState(window.innerWidth);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [url, setUrl] = useState(
    `/products?fabric_types=${params.fabric}&categories=${params.categoryId}&genders=${params.genderId}`
  );

  const [hasMore, sethasMore] = useState(true);

  const [page, setpage] = useState(1);

  const dispatch = useDispatch();

  const {
    products,
    productsAll,
    isLoading,
    next_page,
    filters,
    isLoadingNext,
    prices,
    elasticity_fabric,
    weight_fabric,
  } = useSelector((state: any) => state.products);
  const [company, setCompanies] = useState<any>(null);
  const [clients, setClients] = useState<any>(null);
  const getCompanies = async () => {
    const company_id = localStorage.getItem("company_id");
    let token = localStorage.getItem("token");

    await axiosInstance({
      method: "get",
      url: `/admin/companies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      setCompanies(response.data);
    });
    return "ok";
  };
  const getClients = async () => {
    let token = localStorage.getItem("token");

    await axiosInstance({
      method: "get",
      url: `/clients/for_you`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response) {
      setClients(response.data);
    });
    return "ok";
  };
  const getForYouCompany = async (company_id: string) => {
    let token = localStorage.getItem("token");
    if (company_id) {
      await axiosInstance({
        method: "get",
        url: `/admin/forYouCompany/${company_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(function (response) {
        setForYouCompany(response.data);
      });
      return "ok";
    }
  };

  const onDragEnd = React.useCallback((draggable: any) => {
    const { destination, draggableId } = draggable;

    if (draggableId) {
      if (draggableId.includes("draggable-product")) {
        const productData = JSON.parse(draggableId);

        const productId = productData.draggableId.substring(
          "draggable-product".length
        );
        const currentproductParentId = productData.parentId;
        if (destination && destination.droppableId) {
          if (destination.droppableId.includes("droppable-minature")) {
            const newParentIdDroppable = destination.droppableId.substring(
              "droppable-minature".length
            );
            if (newParentIdDroppable !== currentproductParentId) {
              dispatch(addProducttoFolder(newParentIdDroppable, productId));
              setIsDragging(false);
            }
          }
        }
      }
    }
    setIsDragging(false);
    // eslint-disable-next-line
  }, []);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const onDragStart = () => {
    setIsDragging(true);
  };

  useEffect(() => {
    // Initialize data and fetch companies/clients only once
    const initializeData = async () => {
      const company_id = localStorage.getItem("company_id");
      if (company_id) {
        await Promise.all([
          getCompanies(),
          getClients(),
          getForYouCompany(company_id),
        ]);
      }
    };

    initializeData();
  }, []);

  useEffect(() => {
    // Reset queries when location changes
    const newQueries = [
      `fabric_types=${params.fabric}`,
      `categories=${params.categoryId}`,
      `genders=${params.genderId}`,
    ];
    setQueries(newQueries);

    // Single API call to get initial products and filters
    dispatch(filterAndKeepAll(`/products?${newQueries.join("&")}`));
  }, [location.pathname]);

  useEffect(() => {
    // Only update URL and fetch products when filters actually change
    if (count > 1) {
      // Skip initial render
      const newUrl =
        params.fabric === "denim"
          ? `/products?${queries.join(
              "&"
            )}&min_price=${min_price}&max_price=${max_price}&elasticity_min=${min_elasticity}&elasticity_max=${max_elasticity}&weight_min=${min_weight}&weight_max=${max_weight}`
          : `/products?${queries.join(
              "&"
            )}&min_price=${min_price}&max_price=${max_price}`;

      setUrl(newUrl);
      dispatch(filter(newUrl));
      dispatch(addFilter(queries));
    }
    setcount(count + 1);
  }, [
    min_price,
    max_price,
    min_elasticity,
    max_elasticity,
    min_weight,
    max_weight,
    queries,
  ]);

  useEffect(() => {
    let resize = () =>
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    if (productsAll.length) {
      // let total_prices = productsAll
      //   .filter((product: any) => product.fabric_type === params.fabric)
      //   .map((el: any) => el.total_price);
      // let maxPrice = Math.max(...total_prices);
      // let minPrice = Math.min(...total_prices);
      setMax_price(prices.max_price);
      setMin_price(prices.min_price);
      setMax_price_allowed(prices.max_price);
      setMin_price_allowed(prices.min_price);
      setMin_priceBefore(prices.min_price);
      setMax_priceBefore(prices.max_price);
      setMin_elasticity(elasticity_fabric?.min_elasticity_value);
      setMax_elasticity(elasticity_fabric?.max_elasticity_value);
      setMin_elasticity_before(elasticity_fabric?.min_elasticity_value);
      setMax_elasticity_before(elasticity_fabric.max_elasticity_value);
      setMax_elasticity_allowed(elasticity_fabric?.max_elasticity_value);
      setMin_elasticity_allowed(elasticity_fabric?.min_elasticity_value);

      setMin_weight(weight_fabric?.min_weight_value);
      setMax_weight(weight_fabric?.max_weight_value);
      setMin_weight_before(weight_fabric?.min_weight_value);
      setMax_weight_before(weight_fabric?.max_weight_value);
      setMax_weight_allowed(weight_fabric?.max_weight_value);
      setMin_weight_allowed(weight_fabric?.min_weight_value);

      // if (total_prices.length === 0) {
      //   setMax_price(0);
      //   setMin_price(0);
      //   setMax_price_allowed(0);
      //   setMin_price_allowed(0);
      // }
    }
    // eslint-disable-next-line
  }, [productsAll]);

  const handleChangePrice = (val: any) => {
    let [min, max] = val;
    setMin_price(min);
    setMax_price(max);
  };

  const handleChangePriceAfter = (val: any) => {
    let [min, max] = val;
    setMin_priceBefore(min);
    setMax_priceBefore(max);
  };

  const handleChangeElasticity = (val: any) => {
    let [min, max] = val;
    setMin_elasticity(min);
    setMax_elasticity(max);
  };

  const handleChangeWeight = (val: any) => {
    let [min, max] = val;
    setMin_weight(min);
    setMax_weight(max);
  };

  const addQuery = (target: any) => {
    const element = target.name + "=" + target.id;
    if (target.checked) {
      var exists = queries.filter((query: any) => query.includes(target.name));
      if (exists.length === 0) {
        setQueries([...queries, element]);
      } else {
        var newQueryVal = exists[0].concat("," + target.id);
        setQueries(
          queries.map((el: any) =>
            el.includes(target.name) ? newQueryVal : el
          )
        );
      }
    } else if (!target.checked) {
      var exist = queries.filter((query: any) =>
        query.includes(target.name)
      )[0];
      if (exist.includes(",") === false) {
        setQueries(queries.filter((el) => !el.includes(target.name)));
      } else if (
        exist.indexOf(target.id) ===
        exist.length - target.id.toString().length
      ) {
        let newQuery = exist.substr(
          0,
          exist.length - (target.id.toString().length + 1)
        );
        setQueries(
          queries.map((el: any) => (el.includes(target.name) ? newQuery : el))
        );
      } else {
        let newQuery = exist.replace(target.id + ",", "");
        setQueries(
          queries.map((el: any) => (el.includes(target.name) ? newQuery : el))
        );
      }
    }
  };

  const deleteFilterTag = (obj: any) => {
    var exist = queries.filter((query: any) =>
      query.includes(obj.filteredBy)
    )[0];
    if (exist.includes(",") === false) {
      setQueries(queries.filter((el) => !el.includes(obj.filteredBy)));
    } else if (
      exist.indexOf(obj.id) ===
      exist.length - obj.id.toString().length
    ) {
      let newQuery = exist.substr(
        0,
        exist.length - (obj.id.toString().length + 1)
      );
      setQueries(
        queries.map((el: any) => (el.includes(obj.filteredBy) ? newQuery : el))
      );
    } else {
      let newQuery = exist.replace(obj.id + ",", "");
      setQueries(
        queries.map((el: any) => (el.includes(obj.filteredBy) ? newQuery : el))
      );
    }
  };

  const fetchData = async () => {
    if (next_page) {
      dispatch(
        getProductsNextPage(
          next_page.substr(process.env.REACT_APP_URL_LENGTH) +
            "&" +
            queries.join("&")
        )
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Head title="Products" />
      <div className="products_page_container_product ">
        {/*   <div
          className={`bread_crumb_list_products`}
          style={{
            padding: width < 1000 ? "0 20px" : "0 60px",
            borderBottom: width < 1000 ? "1px solid #e0e0e0" : "none",
          }}
        >
          {width < 1000 && (
            <img
              src={backArrow}
              alt="back arrow"
              className={`${width < 1000 ? "mr-auto" : undefined}`}
              onClick={() => history.back()}
            />
          )}
          <span className={`${width < 1000 ? "mr-auto" : undefined}`}>
            {pathnames.map((el: any, i: number) => {
              const routeTo = `${pathnames
                .slice(0, i + 1)
                .join("/")
                .toLowerCase()} `;
              return i === pathnames.length - 1 ? (
                <span key={i} className="bread_crumb_item">
                  {el}
                </span>
              ) : el !== "Women" &&
                el !== "Men" &&
                el !== "Unisex" &&
                el !== "Kids" &&
                el !== "Collection" ? (
                <span
                  key={i}
                  className="bread_crumb_item"
                  onClick={() => dispatch(handleLeftDrawer())}
                >
                  {el}/
                </span>
              ) : (
                <Link key={i} className="bread_crumb_item" to={`/${routeTo}`}>
                  {el}
                  <span style={{ color: "black" }}>/</span>
                </Link>
              );
            })}
          </span>
        </div>*/}

        <div className={`container-fluid products_page product_margin_top`}>
          {width >= 1000 && (
            <div className="filter_collection">
              <Filter
                setMax_elasticity_before={setMax_elasticity_before}
                setMin_elasticity_before={setMin_elasticity_before}
                max_elasticity_before={max_elasticity_before}
                min_elasticity_before={min_elasticity_before}
                isDragging={isDragging}
                fabric={params.fabric}
                categoryId={params.categoryId}
                genderId={params.genderId}
                handleChangePrice={handleChangePrice}
                min_price={min_price}
                max_price={max_price}
                urlProp={url}
                setUrl={setUrl}
                queries={queries}
                addQuery={addQuery}
                min_price_allowed={min_price_allowed}
                max_price_allowed={max_price_allowed}
                setMax_price={setMax_price}
                setMin_price={setMin_price}
                setMax_price_allowed={setMax_price_allowed}
                setMin_price_allowed={setMin_price_allowed}
                handleChangePriceAfter={handleChangePriceAfter}
                max_priceBefore={max_priceBefore}
                min_priceBefore={min_priceBefore}
                max_elasticity={max_elasticity}
                min_elasticity={min_elasticity}
                handleChangeElasticity={handleChangeElasticity}
                setMax_weight_before={setMax_weight_before}
                setMin_weight_before={setMin_weight_before}
                max_weight_before={max_weight_before}
                min_weight_before={min_weight_before}
                max_weight={max_weight}
                min_weight={min_weight}
                handleChangeWeight={handleChangeWeight}
              />
            </div>
          )}
          <div className="products_list_collection">
            <div className="filter_tags_wrapper mt-3">
              <div style={{ width: "90%" }}>
                {width >= 1000 ? (
                  ((filters &&
                    filters.filter((el: any) => el.filteredBy !== "genders")
                      .length > 0) ||
                    max_price_allowed !== max_price ||
                    min_price_allowed !== min_price ||
                    max_elasticity !== max_elasticity_allowed ||
                    min_elasticity !== min_elasticity_allowed ||
                    max_weight !== max_weight_allowed ||
                    min_weight !== min_weight_allowed) && (
                    <p>Selected Filters:</p>
                  )
                ) : (
                  <button
                    className="filter_product_button"
                    onClick={() => setOpenFilterDrawer(true)}
                  >
                    <FilterOutlined className="mr-2" /> Filter
                  </button>
                )}
                {/* FILTER DRAWER */}
                <Drawer
                  title="FILTER"
                  placement={"left"}
                  closable={true}
                  onClose={() => setOpenFilterDrawer(false)}
                  visible={openFilterDrawer}
                  key={"left"}
                  style={{
                    width: "100%",
                    display: openFilterDrawer ? "unset" : "none",
                  }}
                >
                  <div className="p-3">
                    <Filter
                      handleChangeElasticity={handleChangeElasticity}
                      setMax_elasticity_before={setMax_elasticity_before}
                      setMin_elasticity_before={setMin_elasticity_before}
                      max_elasticity_before={max_elasticity_before}
                      min_elasticity_before={min_elasticity_before}
                      isDragging={isDragging}
                      fabric={params.fabric}
                      categoryId={params.categoryId}
                      genderId={params.genderId}
                      handleChangePrice={handleChangePrice}
                      min_price={min_price}
                      max_price={max_price}
                      urlProp={url}
                      setUrl={setUrl}
                      queries={queries}
                      addQuery={addQuery}
                      min_price_allowed={min_price_allowed}
                      max_price_allowed={max_price_allowed}
                      handleChangePriceAfter={handleChangePriceAfter}
                      max_priceBefore={max_priceBefore}
                      min_priceBefore={min_priceBefore}
                      max_elasticity={max_elasticity}
                      min_elasticity={min_elasticity}
                      setMax_weight_before={setMax_weight_before}
                      setMin_weight_before={setMin_weight_before}
                      max_weight_before={max_weight_before}
                      min_weight_before={min_weight_before}
                      max_weight={max_weight}
                      min_weight={min_weight}
                      handleChangeWeight={handleChangeWeight}
                    />
                  </div>
                </Drawer>
                <div className="filter_tags">
                  {/* {((filters && filters.length > 0) ||
                    max_price_allowed !== max_price ||
                    min_price_allowed !== min_price ||
                    max_elasticity !== 100 ||
                    min_elasticity !== 0) && (
                    <div
                      className="filter_tag"
                      onClick={() => {
                        setMax_price(prices.max_price);
                        setMin_price(prices.min_price);
                        setMin_priceBefore(prices.min_price);
                        setMax_priceBefore(prices.max_price);
                        setMax_elasticity(100);
                        setMin_elasticity(0);
                        setMax_elasticity_before(100);
                        setMin_elasticity_before(0);
                        dispatch(clearAllFilters());
                        setQueries([]);
                      }}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "rgba(255, 0, 69, 0.1)",
                        border: "2px solid rgba(255, 0, 69, 0.4)",
                        boxSizing: "border-box",
                        padding: "8px 13px",
                      }}
                    >
                      <p>Clear filters</p>
                      <span>
                        <ClearOutlined
                          style={{ transform: "translateY(-3px)" }}
                        />
                      </span>
                    </div>
                  )} */}
                  {(max_price_allowed !== max_price ||
                    min_price_allowed !== min_price) && (
                    <div className="filter_tag">
                      <p> Price : {`${min_price} - ${max_price}`}</p>
                      <span
                        onClick={() => {
                          setMax_price(prices.max_price);
                          setMin_price(prices.min_price);
                          setMin_priceBefore(prices.min_price);
                          setMax_priceBefore(prices.max_price);
                        }}
                      >
                        X
                      </span>
                    </div>
                  )}
                  {(max_elasticity !== max_elasticity_allowed ||
                    min_elasticity !== min_elasticity_allowed) && (
                    <div className="filter_tag">
                      <p>
                        {" "}
                        Elasticity : {`${min_elasticity} - ${max_elasticity}`}
                      </p>
                      <span
                        onClick={() => {
                          setMax_elasticity(
                            elasticity_fabric.max_elasticity_value
                          );
                          setMin_elasticity(
                            elasticity_fabric.min_elasticity_value
                          );
                          setMax_elasticity_before(
                            elasticity_fabric.max_elasticity_value
                          );
                          setMin_elasticity_before(
                            elasticity_fabric.min_elasticity_value
                          );
                        }}
                      >
                        X
                      </span>
                    </div>
                  )}

                  {(max_weight !== max_weight_allowed ||
                    min_weight !== min_weight_allowed) && (
                    <div className="filter_tag">
                      <p>Weight : {`${min_weight} - ${max_weight}`}</p>
                      <span
                        onClick={() => {
                          setMax_weight(weight_fabric.max_weight_value);
                          setMin_weight(weight_fabric.min_weight_value);
                          setMax_weight_before(weight_fabric.max_weight_value);
                          setMin_weight_before(weight_fabric.min_weight_value);
                        }}
                      >
                        X
                      </span>
                    </div>
                  )}
                  {filters &&
                    filters.map(
                      (el: any, i: number) =>
                        !(
                          el.filteredBy === "genders" &&
                          location.pathname.includes("/product")
                        ) && (
                          <div className="filter_tag" key={i}>
                            <p>{el.name}</p>
                            <span
                              onClick={() => {
                                deleteFilterTag(el);
                                dispatch(deleteFilter(el.name));
                              }}
                            >
                              X
                            </span>
                          </div>
                        )
                    )}
                </div>
              </div>
              {/* <p  className="num_items ml-2">{products && products.length} ITEMS</p> */}
            </div>
            {isLoading ? (
              <div className="row" style={{ gap: "2.2rem" }}>
                {Array.from({ length: 10 }, (_, index) => index + 1).map(
                  (_, i) => (
                    <div className="col-12 col-md-2" key={i}>
                      <div className="d-flex flex-column">
                        <Card
                          loading={isLoading}
                          bordered={false}
                          style={{ width: "100%" }}
                          bodyStyle={{ padding: "5px" }}
                          cover={
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              src={placeholder_jeans}
                            />
                          }
                        >
                          <Meta title={"Code"} description={"Name"} />
                        </Card>
                        {/* <Skeleton.Avatar
                          active={true}
                          shape={"square"}
                          style={{ width: 300, height: 500 }}
                        />
                        <Skeleton active={true} /> */}
                      </div>
                    </div>
                  )
                )}{" "}
              </div>
            ) : products && products.length > 0 ? (
              <Droppable
                droppableId={`products-list-droppable`}
                direction="horizontal"
                key={uuidv4()}
              >
                {(provided, snapshot) => (
                  <InfiniteScroll
                    dataLength={products.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={hasMore}
                    loader={""}
                  >
                    <div
                      // className={`row products_elements ${
                      //   width < 1000 ? "justify-content-center" : ""
                      // }`}
                      className="products-grid"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {products.map((product: any, index: number) => (
                        // <StandardDraggableProductCard

                        <>
                          <ExampleProductCard
                            key={uuidv4()}
                            index={index}
                            productPage={true}
                            product={product}
                            foryou={forYouCompany}
                            company={company}
                            clients={clients}
                            item={{
                              main: product.images[0]
                                ? product.images[0].path
                                : "",
                              onHover: product.images[1]
                                ? product.images[1].path
                                : "",
                            }}
                          />
                        </>
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
              </Droppable>
            ) : filters && filters.length !== 1 && products.length === 0 ? (
              <div className="unavailable_products_container">
                <h3 className="unavailable_products">
                  No products available with the selected filters !
                </h3>
              </div>
            ) : (
              filters &&
              filters.length === 0 &&
              products.length === 0 && (
                <div className="unavailable_products_container">
                  <h3 className="unavailable_products">
                    No products available for you for the moment !
                  </h3>
                </div>
              )
            )}

            {/*    {!isLoading && next_page && (
              <div style={{ textAlign: "center" }} className="mb-5 mt-4">
                <button
                  className="load_more_btn"
                  onClick={() => {
                    getNextPageProducts();
                  }}
                >
                  LOAD MORE
                  {isLoadingNext}
                </button>
                {isLoadingNext && <Spinner />}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};
