import {
  Row,
  Col,
  Card,
  Pagination,
  Result,
  Button,
  Popconfirm,
  Modal,
  Select,
} from "antd";
import { useSelector } from "react-redux";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  FileExcelOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import LoadingLibraryContent from "./LoadingLibraryContent";
import LazyImg from "./LazyImage";
import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../config/axios-instance";
import { useQuery } from "react-query";
import { HrIcon } from "../product-3d-config-tabs/forms/canvas/icon/HrIcon";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "../Spinner";
import { MyNotif } from "../NotifIcons/NotifIcons";
import { useDropzone } from "react-dropzone";
import no_result from "../../img/no_result.png";
import { v4 as uuidv4 } from "uuid";

const { Meta } = Card;

// Add interface for fabric data
interface FabricData {
  id: number;
  name: string;
  fabric_type: string;
  price: number;
  price_kg: number;
  weight_value: number;
  elasticity_value: number;
  images: Array<{image: string}>;
  fabricMill?: {
    name: string;
  };
  is_out?: boolean;
  [key: string]: any; // for other properties
}

// Add pagination interface
interface PaginationData {
  current_page: number;
  last_page: number;
  total: number;
  per_page: number;
}

const Content = ({
                   content,
                   setSelectedItem,
                   isLoading,
                   isFetching,
                   addNewFabric,
                   elasticity_value,
                   weight_value,
                   prices,
                   minPrice,
                   maxPrice,
                   minWeigth,
                   maxWeigth,
                   minElasticity,
                   maxElasticity,
                   handleChangePriceAfter,
                   handleChangeWeightAfter,
                   handleChangeElasticityAfter
                 }: any) => {
  const { user, isAdmin } = useSelector((state: any) => state.auth);
  const [dataToMap, setDataToMap] = useState<FabricData[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<{[key: string]: {[key: number]: boolean}}>({});
  const [sliderValues, setSliderValues] = useState({
    elasticity: [minElasticity || 0, maxElasticity || 100],
    weight: [minWeigth || 10, maxWeigth || 90],
    price: [minPrice || 0, maxPrice || 1000]
  });
  const [paginationData, setPaginationData] = useState<PaginationData>({
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 30
  });

  const history = useHistory();
  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryy();

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const location = useLocation(); // Used to get the current location.

  const getFabricTypeFromQuery = () => {
    const params = new URLSearchParams(location.search);
    return params.get("fabric_type");
  };

  useEffect(() => {
    setCurrentPage(1); // Reset page to 1
    setDataToMap([]); // Clear current data
    fetchData(1); // Fetch new data
  }, [location.search]);

  const fetchData = (page: number) => {
    setLoading(true);
    let token = localStorage.getItem("token");
    const fabricType = new URLSearchParams(window.location.search).get("fabric_type");

    const params: { [key: string]: string | number } = {
      page,
      limit: 100
    };

    if (fabricType) {
      params.fabric_type = fabricType;
    }

    return axiosInstance({
      method: "get",
      url: '/fabrics',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      const { data, current_page, last_page, total, per_page } = response.data;
      
      if (data && data.length > 0) {
        if (page === 1) {
          setDataToMap(data);
        } else {
          setDataToMap(prev => [...prev, ...data]);
        }
        setPaginationData({
          current_page,
          last_page,
          total,
          per_page
        });
        setHasMore(current_page < last_page);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (content?.data) {
      setDataToMap(content.data); // Update dataToMap with content data
    }
  }, [content?.data]);

  console.log(content?.data);
  const deleteFabric = (id: any) => {
    let token = localStorage.getItem("token");
    console.log(id);

    return axiosInstance({
      method: "delete",
      url: `deleteFabrics/` + id,
      // data: { ...data, _method: "post", typeFilter },
      data: id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
        .then(async () => {
          //  await refetch();
          window.location.reload();
        })
        .catch((err) => console.log(err));
  };

  const handleLoadMore = () => {
    if (loading || !hasMore) return;
    const nextPage = paginationData.current_page + 1;
    if (searchQuery || hasActiveFilters()) {
      handleSearchWithFilters(searchQuery, sliderValues, nextPage);
    } else {
      fetchData(nextPage);
    }
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<FabricData[]>([]);

  const onPriceChange = (values: number[]) => {
    handleChangePriceAfter(values);
    handleSearchWithFilters(searchQuery);
  };

  const onWeightChange = (values: number[]) => {
    handleChangeWeightAfter(values);
    handleSearchWithFilters(searchQuery);
  };

  const onElasticityChange = (values: number[]) => {
    handleChangeElasticityAfter(values);
    handleSearchWithFilters(searchQuery);
  };

  useEffect(() => {
    handleSearchWithFilters(searchQuery);
  }, [minPrice, maxPrice, minWeigth, maxWeigth, minElasticity, maxElasticity]);

  const handleSearchWithFilters = (query: string, currentSliderValues: any = sliderValues, page: number = 1) => {
    if (!query.trim() && !hasActiveFilters() && !hasActiveSliderFilters(currentSliderValues)) {
      setFilteredData([]);
      setDataToMap([]);
      return;
    }

    let token = localStorage.getItem("token");
    const fabricType = new URLSearchParams(window.location.search).get("fabric_type");

    // Get all active filters
    const filters: { [key: string]: string | number } = {
      page,
      limit: 100
    };

    // Only add filters that have values
    if (fabricType) filters.fabric_type = fabricType;
    if (query) filters.query = query;
    if (getCheckedIds('colors').length > 0) filters.colors = getCheckedIds('colors').join(',');
    if (getCheckedIds('elasticities').length > 0) filters.elasticities = getCheckedIds('elasticities').join(',');
    if (getCheckedIds('weights').length > 0) filters.weights = getCheckedIds('weights').join(',');
    if (getCheckedIds('fibers').length > 0) filters.fibers = getCheckedIds('fibers').join(',');
    if (getCheckedIds('fabricmill').length > 0) filters.fabricmill = getCheckedIds('fabricmill').join(',');
    if (getCheckedIds('fabric_type_id').length > 0) filters.fabric_type_id = getCheckedIds('fabric_type_id').join(',');
    if (getCheckedIds('special_fiber').length > 0) filters.special_fiber = getCheckedIds('special_fiber').join(',');
    
    // Add slider values only if they differ from defaults
    if (minPrice && minPrice !== 0) filters.price_min = minPrice.toString();
    if (maxPrice && maxPrice !== 1000) filters.price_max = maxPrice.toString();
    if (minWeigth && minWeigth !== 0) filters.weight_min = minWeigth.toString();
    if (maxWeigth && maxWeigth !== 1000) filters.weight_max = maxWeigth.toString();
    if (minElasticity && minElasticity !== 0) filters.elasticity_min = minElasticity.toString();
    if (maxElasticity && maxElasticity !== 100) filters.elasticity_max = maxElasticity.toString();

    console.log("Sending search with filters:", filters);

    axiosInstance({
      method: "get",
      url: '/fabrics/search',
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then((response) => {
      const { data, current_page, last_page, total, per_page } = response.data;
      console.log("Search response:", response.data);
      
      if (page === 1) {
        setFilteredData(data);
        setDataToMap(data);
      } else {
        setFilteredData(prev => [...prev, ...data]);
        setDataToMap(prev => [...prev, ...data]);
      }
      
      setPaginationData({
        current_page,
        last_page,
        total,
        per_page
      });
      setHasMore(current_page < last_page);
      setCurrentPage(current_page);
    })
    .catch((error) => {
      console.error("Search error:", error);
      setFilteredData([]);
      setDataToMap([]);
    });
  };

  const handleSearch = (query: any) => {
    setSearchQuery(query);
    handleSearchWithFilters(query);
  };

  const hasActiveFilters = () => {
    return (
      getCheckedIds('colors').length > 0 ||
      getCheckedIds('fabricmill').length > 0 ||
      getCheckedIds('special_fiber').length > 0 ||
      getCheckedIds('fabric_type_id').length > 0
    );
  };

  const hasActiveSliderFilters = (currentValues: any = sliderValues) => {
    return (
      currentValues.elasticity[0] !== (minElasticity || 0) ||
      currentValues.elasticity[1] !== (maxElasticity || 100) ||
      currentValues.weight[0] !== (minWeigth || 10) ||
      currentValues.weight[1] !== (maxWeigth || 90) ||
      currentValues.price[0] !== (minPrice || 0) ||
      currentValues.price[1] !== (maxPrice || 1000)
    );
  };

  const getCheckedIds = (category: string) => {
    if (!checkedItems[category]) return [];
    return Object.entries(checkedItems[category])
      .filter(([_, isChecked]) => isChecked)
      .map(([id, _]) => parseInt(id));
  };

  // Remove the useEffect that watches sliderValues since we're handling updates in the handlers
  useEffect(() => {
    handleSearchWithFilters(searchQuery);
  }, [checkedItems]); // Only watch checkedItems now

  // Debounce the search to avoid too many API calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchQuery) {
        handleSearch(searchQuery);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  /*
const dataToMap = searchQuery ? filteredData : content?.data;
*/

  const dataToDisplay = searchQuery ? filteredData : dataToMap;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenImage, setIsModalOpenImage] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const containerRef = useRef<any>();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".xlsx",

    maxFiles: 1,
    onDragEnter: () => (containerRef.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRef.current.style.background = "#f4f7ff"),
  });

  const containerRefImage = useRef<any>();
  const {
    acceptedFiles: acceptedFilesImage,
    getRootProps: getRootPropsImage,
    getInputProps: getInputPropsImgae,
  } = useDropzone({
    accept: ".zip",

    maxFiles: 1,
    onDragEnter: () => (containerRefImage.current.style.background = "#e5f1fa"),
    onDragLeave: () => (containerRefImage.current.style.background = "#f4f7ff"),
  });

  const files = acceptedFiles.map((file: any) => (
      <div key={file.path}>{file.path}</div>
  ));

  const filesImage = acceptedFilesImage.map((file: any) => (
      <div key={file.path}>{file.path}</div>
  ));
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalImage = () => {
    setIsModalOpenImage(true);
  };

  const [type, setType] = useState("denim");

  const handleOk = () => {
    let token = localStorage.getItem("token");

    if (acceptedFiles.length > 0) {
      setConfirmLoading(true);
      const formData = new FormData();
      // Append the file to the FormData instance
      // 'file' is the name of the input expected by your Laravel backend

      formData.append("file", acceptedFiles[0]);
      formData.append("type", type);
      if (type == "denim") {
        // Replace 'YOUR_API_ENDPOINT' with the actual endpoint
        axiosInstance
            .post("/import-fabric-denim", formData, {
              headers: {
                "Content-Type": "multipart/form-data", // Important for files, especially if your server checks for this header
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              // Handle the successful upload response here
              setIsModalOpen(false);
              fetchData(1);
              setConfirmLoading(false);

              MyNotif("Fabric imported successfully.", "success");
            })
            .catch((error) => {
              // Handle errors here
              console.error("Error uploading file:", error);
            });
      } else {
        axiosInstance
            .post("/import-fabric-knit", formData, {
              headers: {
                "Content-Type": "multipart/form-data", // Important for files, especially if your server checks for this header
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              // Handle the successful upload response here
              setIsModalOpen(false);
              fetchData(1);
              setConfirmLoading(false);

              MyNotif("Fabric imported successfully.", "success");
            })
            .catch((error) => {
              // Handle errors here
              console.error("Error uploading file:", error);
            });
      }
    }
  };

  const handleOkImage = () => {
    let token = localStorage.getItem("token");

    if (acceptedFilesImage.length > 0) {
      setConfirmLoading(true);
      const formData = new FormData();
      // Append the file to the FormData instance
      // 'file' is the name of the input expected by your Laravel backend

      formData.append("file", acceptedFilesImage[0]);
      formData.append("type", type);

      axiosInstance
          .post("/import-fabric-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Important for files, especially if your server checks for this header
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            // Handle the successful upload response here
            setIsModalOpenImage(false);
            fetchData(1);
            setConfirmLoading(false);

            MyNotif("Fabric imported successfully.", "success");
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error uploading file:", error);
          });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelImage = () => {
    setIsModalOpenImage(false);
  };

  const handleChangeSelect = (value: any) => {
    setType(value);
  };

  // @ts-ignore
  return (
      <div style={{ background: "transparent" }}>
        <Row justify="space-between" style={{ paddingTop: "15px" }}>
          <div>
            <h2 className={"list_of_companies_title"}>Demco's Fabric Library</h2>
            {user && isAdmin && (
                <div className=" ">
                  <Button
                      type="default"
                      className="approve_btn"
                      onClick={() => {
                        addNewFabric();
                      }}
                  >
                    Add new fabric
                  </Button>
                </div>
            )}
          </div>{" "}
          {user && isAdmin && (
              <>
                {/*            <div className="proto_footer_btns ">
                <Button
                    type="default"
                    className="approve_btn"
                    onClick={() => {
                      addNewFabric();
                    }}
                >
                Add new fabric
                </Button>
              </div>*/}

                <div
                    className={" row"}
                    style={{ alignItems: "center", display: "flex", gap: "10px" }}
                >
                  <button
                      onClick={showModal}
                      className={"view_history"}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        gap: "10px",
                      }}
                  >
                    Upload Sheet
                    <FileExcelOutlined />
                  </button>

                  <Modal
                      title="Upload your file"
                      okText="Upload"
                      confirmLoading={confirmLoading}
                      visible={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                  >
                    <Select
                        onChange={handleChangeSelect}
                        style={{ width: "100%" }}
                        defaultValue={"denim"}
                        size={"large"}
                        className={"select_add_product"}
                    >
                      <option value="denim" selected>
                        Denim
                      </option>
                      <option value="knit">Knit</option>
                    </Select>
                    <section className="container" style={{ marginTop: "25px" }}>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        {acceptedFiles.length > 0 ? (
                            <>
                              <ul>{files}</ul>
                            </>
                        ) : (
                            <>
                              <p>
                                Drag 'n' drop some files here, or click to select
                                files
                              </p>
                            </>
                        )}
                      </div>
                    </section>
                  </Modal>

                  <button
                      onClick={showModalImage}
                      className={"view_history"}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        gap: "10px",
                      }}
                  >
                    Upload Image <FileZipOutlined />
                  </button>

                  <Modal
                      title="Upload your file Zip"
                      okText="Upload"
                      confirmLoading={confirmLoading}
                      visible={isModalOpenImage}
                      onOk={handleOkImage}
                      onCancel={handleCancelImage}
                  >
                    <Select
                        onChange={handleChangeSelect}
                        style={{ width: "100%" }}
                        defaultValue={"denim"}
                        size={"large"}
                        className={"select_add_product"}
                    >
                      <option value="denim" selected>
                        Denim
                      </option>
                      <option value="knit">Knit</option>
                    </Select>
                    <section className="container" style={{ marginTop: "25px" }}>
                      <div {...getRootPropsImage({ className: "dropzone" })}>
                        <input {...getInputPropsImgae()} />
                        {acceptedFilesImage.length > 0 ? (
                            <>
                              <ul>{filesImage}</ul>
                            </>
                        ) : (
                            <>
                              <p>
                                Drag 'n' drop some files here, or click to select
                                files
                              </p>
                            </>
                        )}
                      </div>
                    </section>
                  </Modal>
                </div>
              </>
          )}
        </Row>
        <div className="row mt-4 mb-4">
          <div className="search-bar searchfaq" style={{ width: "100%" }}>
            <div className="input-container">
              <input
                  type="text"
                  placeholder="Search your fabric..."
                  onChange={(e) => handleSearch(e.target.value)}
                  className={"search_input"}
              />
              <HrIcon></HrIcon>
              <button type="submit" className={"submit_input"}>
                <SearchOutlined />
              </button>
            </div>
          </div>
        </div>
        <Row gutter={15}>
          {isLoading || isFetching ? (
              // @ts-ignore
              <LoadingLibraryContent />
          ) : content && content?.data?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center h-100 w-100">
                <img src={no_result} />
              </div>
          ) : (
              <>
                <InfiniteScroll
                    dataLength={dataToMap.length}
                    next={handleLoadMore}
                    hasMore={hasMore && !loading}
                    loader={<div className="text-center py-4"><Spinner /></div>}
                    endMessage={
                      <div style={{ textAlign: "center", padding: "20px" }}>
                        {dataToMap.length > 0 ? "You have seen it all" : "No results found"}
                      </div>
                    }
                >
                  <Row gutter={15}>
                    {dataToDisplay.map((item: any) => (
                      <Col
                          className="gutter-row"
                          key={`fabric-${item.id}-${uuidv4()}`}
                          xs={dataToDisplay.length === 1 ? 24 : 12}
                          sm={dataToDisplay.length === 1 ? 24 : 8}
                          lg={dataToDisplay.length === 1 ? 24 : 6}
                          xl={dataToDisplay.length === 1 ? 24 : 4}
                          style={{ marginTop: 15 }}
                      >
                        <Link
                            to={`/library/${item.id}`}
                            className="fabric-name-link"
                            key={`link-${item.id}`}
                        >
                          <Card
                              style={{ cursor: "pointer" }}
                              bodyStyle={{ padding: "15px 0" }}
                              className="library-card"
                              bordered={false}
                              key={`card-${item.id}`}
                              cover={
                                <LazyImg
                                    key={`img-${item.id}`}
                                    alt={`fabric-${item.id}`}
                                    src={
                                      item.images[0]?.image
                                          ? `${process.env.REACT_APP_STORAGE_URL}${item.images[0]?.image}`
                                          : null
                                    }
                                    thumb={
                                      item.images[0]?.image
                                          ? `${process.env.REACT_APP_STORAGE_URL}${item.images[0]?.image}`
                                          : null
                                    }
                                    style={{
                                      objectFit: "cover",
                                      borderRadius: 0,
                                      height: 263,
                                    }}
                                />
                              }
                              actions={
                                user &&
                                isAdmin &&
                                user.permissions.includes("fabric-library.update") && [
                                  <EditOutlined
                                      key={`edit-${item.id}`}
                                      onClick={() => setSelectedItem(item)}
                                  />,
                                  <Popconfirm
                                      key={`popconfirm-${item.id}`}
                                      placement="top"
                                      title="Are you sure you want to delete this fabric?"
                                      onConfirm={() => deleteFabric(item.id)}
                                      okText="Yes"
                                      cancelText="No"
                                  >
                                    <DeleteOutlined key={`delete-${item.id}`} />
                                  </Popconfirm>,
                                ]
                              }
                          >
                            <Meta
                                key={`meta-${item.id}`}
                                title={
                                  <Link
                                      to={`/library/${item.id}`}
                                      className="fabric-name-link"
                                      key={`title-link-${item.id}`}
                                  >
                                    {item.name}{item?.is_out? " (Out of collection)":"" } <br/>
                                    {item.fabric_mill?.name} 
                                  </Link>
                                }
                            />
                          </Card>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </InfiniteScroll>
              </>
          )}
        </Row>
      </div>
  );
};

export default Content;

