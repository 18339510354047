import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useHistory } from "react-router-dom";
import axiosInstance from "../config/axios-instance";
import Sidebar from "../components/Library/Sidebar";
import Content from "../components/Library/Content";
import LibraryDrawer from "../components/Library/LibraryDrawer";
import "../sass/Library.scss";
import Head from "../components/Head";
import AddLibraryDrawer from "../components/Library/AddLibraryDrawer";
import { FilterOutlined } from "@ant-design/icons";

const Library = () => {
  const [isTrims, setIsTrims] = useState(false);
  const [isDenim, setIsDenim] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [minPrice, setMin_price_before] = useState(0);
  const [maxPrice, setMax_price_before] = useState(1000);
  const [minWeigth, setMin_weight_before] = useState(0);
  const [maxWeigth, setMax_weight_before] = useState(1000);
  const [minElasticity, setMin_elasticity_before] = useState(0);
  const [maxElasticity, setMax_elasticity_before] = useState(1000);

  const [queries, setQueries] = useState<any>([]);
  function useQueryy() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryy();
  const history = useHistory();
  /*  const [url, setUrl] = useState(
    `/fabrics?fabric_type=${query.get("fabric_type")}&page=${query.get("page")}`
  );*/

  const [url, setUrl] = useState(
      `/fabrics?fabric_type=${query.get("fabric_type")}&page=${query.get("page")}`
  );

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const showDrawerAdd = () => {
    setVisibleAdd(true);
  };
  const onCloseAdd = () => {
    setVisibleAdd(false);
  };
  // colors
  const { isLoading: colorsIsLoading, data: colors } = useQuery(
      "color",
      () => fetchType("colors"),
      { keepPreviousData: true }
  );

  const { isLoading: fabriccolorsIsLoading, data: fabriccolors } = useQuery(
      "fabriccolors",
      () => fetchType("fabriccolors"),
      { keepPreviousData: true }
  );
  const { isLoading: fabricMillLoading, data: fabricMill } = useQuery(
      "fabricmill",
      () => fetchType("fabricmill"),
      { keepPreviousData: true }
  );
  const { isLoading: fabricTypeLoading, data: fabricType } = useQuery(
      "fabrictype",
      () => fetchType("fabrictype"),
      { keepPreviousData: true }
  );

  const { isLoading: specialFiberLoading, data: specialFiber } = useQuery(
      "specialfiber",
      () => fetchType("specialfiber"),
      { keepPreviousData: true }
  );
  const { isLoading: PriceLoading, data: prices } = useQuery(
      "get_prices",
      () => fetchType("get_prices"),
      { keepPreviousData: true }
  );

  const { isLoading: weigthLoading, data: weight_value } = useQuery(
      "get_weights",
      () => fetchType("get_weights"),
      { keepPreviousData: true }
  );
  // elasticity
  const { isLoading: elasticityIsLoading, data: elasticity } = useQuery(
      "elasticities",
      () => fetchType("elasticities"),
      {
        keepPreviousData: true,
      }
  );
  // weight
  const { isLoading: weightIsLoading, data: weight } = useQuery(
      "weights",
      () => fetchType("weights"),
      {
        keepPreviousData: true,
      }
  );

  const { isLoading: elasticityValueIsLoading, data: elasticity_value } =
      useQuery("elasticity_value", () => fetchType("get_elasticity"), {
        keepPreviousData: true,
      });
  // // fibers
  // const { isLoading: fiberIsLoading, data: fibers } = useQuery(
  //   "fibers",
  //   () => fetchType("fibers"),
  //   {
  //     keepPreviousData: true,
  //   }
  // );
  // fibers
  const { isLoading: compositionsIsLoading, data: compositions } = useQuery(
      "compositions",
      () => fetchType("compositions"),
      {
        keepPreviousData: true,
      }
  );
  // washes
  const { data: washes } = useQuery("washes", () => fetchType("washes"), {
    keepPreviousData: true,
  });
  // content
  const { data, isLoading, isFetching, refetch } = useQuery(
      ["content", query.get("fabric_type"), url],
      () => fetchData(url),
      {
        keepPreviousData: true,
      }
  );

  useEffect(() => {
    if (query.get("fabric_type") === "knit") {
      history.push("/library?fabric_type=knit&page=1");
      setUrl("/fabrics?fabric_type=knit&page=1");
    } else {
      history.push("/library?fabric_type=denim&page=1");
      setUrl("/fabrics?fabric_type=denim&page=1");
    }
  }, [isDenim]);

  const fetchType = (type: string) => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url: `/${type}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const fetchData = (url: string) => {
    let token = localStorage.getItem("token");
    return axiosInstance({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const handleChangePriceAfter = (val: any) => {
    let [min, max] = val;
    setMin_price_before(min);
    setMax_price_before(max);
  };
  const handleChangeWeightAfter = (val: any) => {
    let [min, max] = val;
    setMin_weight_before(min);
    setMax_weight_before(max);
  };
  const handleChangeElasticityAfter = (val: any) => {
    let [min, max] = val;
    setMin_elasticity_before(min);
    setMax_elasticity_before(max);
  };

  useEffect(() => {
    console.log("Filters changed", { minPrice, maxPrice, queries });
    setUrl(
        `/fabrics?fabric_type=${query.get("fabric_type")}`.concat(
            "&" + queries.join("&")
        )
    );
  }, [
    minElasticity,
    maxElasticity,
    maxWeigth,
    minWeigth,
    minPrice,
    maxPrice,
    queries,
  ]);
  /*  useEffect(() => {
    setUrl(
      `/fabrics?fabric_type=${query.get(
        "fabric_type"
      )}&min_elasticity=${minElasticity}&max_elasticity=${maxElasticity}&min_weigth=${minWeigth}&max_weigth=${maxWeigth}&min_price=${minPrice}&max_price=${maxPrice}&page=${query.get(
        "page"
      )}`.concat("&" + queries.join("&"))
    );
  }, [
    minElasticity,
    maxElasticity,
    maxWeigth,
    minWeigth,
    minPrice,
    maxPrice,
    queries,
  ]);*/

  const checkboxChange = (name: string, id: number, checked: boolean) => {
    const element = name + "=" + id;
    if (checked) {
      var exists = queries.filter((query: any) => query.includes(name));
      if (exists.length === 0) {
        setQueries([...queries, element]);
      } else {
        var newQueryVal = exists[0].concat("," + id);
        setQueries(
            queries.map((el: any) => (el.includes(name) ? newQueryVal : el))
        );
      }
    } else if (!checked) {
      var exist = queries.filter((query: any) => query.includes(name))[0];
      if (exist.includes(",") === false) {
        setQueries(queries.filter((el: any) => !el.includes(name)));
      } else if (exist.indexOf(id) === exist.length - id.toString().length) {
        let newQuery = exist.substr(
            0,
            exist.length - (id.toString().length + 1)
        );
        setQueries(
            queries.map((el: any) => (el.includes(name) ? newQuery : el))
        );
      } else {
        let newQuery = exist.replace(id + ",", "");
        setQueries(
            queries.map((el: any) => (el.includes(name) ? newQuery : el))
        );
      }
    }
  };

  return (
      <div className="bookings-page">
        <Head title="DEMCO's Library" />
        <header>
          <div className="header-content">
            <div className="navbarr">
              <a href="#side" id="toggle-filter-menu">
              <span className="material-icons-sharp menu-icon">
                {" "}
                <FilterOutlined className="mr-2" />
              </span>
              </a>
            </div>
          </div>
        </header>
        <span id="pop"></span>
        <Sidebar
            setIsTrims={setIsTrims}
            isTrims={isTrims}
            isDenim={isDenim}
            setIsDenim={setIsDenim}
            colors={colors?.data}
            prices={prices?.data}
            fabriccolors={fabriccolors?.data}
            fabricmill={fabricMill?.data}
            colorsIsLoading={colorsIsLoading}
            elasticityIsLoading={elasticityIsLoading}
            elasticity={elasticity?.data}
            weightIsLoading={weightIsLoading}
            weight={weight?.data}
            weight_value={weight_value?.data}
            fabricType={fabricType?.data}
            specialFiber={specialFiber?.data}
            elasticity_value={elasticity_value?.data}
            // fiberIsLoading={fiberIsLoading}
            // fibers={fibers?.data}
            compositionsIsLoading={compositionsIsLoading}
            compositions={compositions?.data}
            checkboxChange={checkboxChange}
            queryFabricType={query.get("fabric_type")}
            handleChangePriceAfter={handleChangePriceAfter}
            handleChangeWeightAfter={handleChangeWeightAfter}
            handleChangeElasticityAfter={handleChangeElasticityAfter}
        />
        <main id="main-doc">
          <div className="container-fluid">
            <section className="main-section" id="introduction">
              <Content
                  content={data?.data}
                  isLoading={isLoading}
                  isFetching={isFetching}
                  addNewFabric={() => {
                    showDrawerAdd();
                  }}
                  currentPage={query.get("page")}
                  setSelectedItem={(item: any) => {
                    setSelectedItem(item);
                    showDrawer();
                  }}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  minWeigth={minWeigth}
                  maxWeigth={maxWeigth}
                  minElasticity={minElasticity}
                  maxElasticity={maxElasticity}
                  handleChangePriceAfter={handleChangePriceAfter}
                  handleChangeWeightAfter={handleChangeWeightAfter}
                  handleChangeElasticityAfter={handleChangeElasticityAfter}
                  elasticity_value={elasticity_value?.data}
                  weight_value={weight_value?.data}
                  prices={prices?.data}
              />
            </section>
          </div>
        </main>
        <AddLibraryDrawer
            elasticity={elasticity?.data}
            colors={colors?.data}
            fabriccolors={fabriccolors?.data}
            fabricmill={fabricMill?.data}
            fabricType={fabricType?.data}
            // fibers={fibers?.data}
            washes={washes?.data}
            specialFiber={specialFiber?.data}
            weights={weight?.data}
            compositions={compositions?.data}
            elasticities={elasticity?.data}
            //item={selectedItem}
            refetch={refetch}
            onClose={() => {
              onCloseAdd();
            }}
            visible={visibleAdd}
        ></AddLibraryDrawer>
        <LibraryDrawer
            elasticity={elasticity?.data}
            colors={colors?.data}
            // fibers={fibers?.data}
            fabriccolors={fabriccolors?.data}
            fabricmill={fabricMill?.data}
            washes={washes?.data}
            weights={weight?.data}
            fabricType={fabricType?.data}
            specialFiber={specialFiber?.data}
            item={selectedItem}
            compositions={compositions?.data}
            elasticities={elasticity?.data}
            refetch={refetch}
            onClose={() => {
              onClose();
              setSelectedItem(null);
            }}
            visible={visible}
        />
      </div>
  );
};

export default Library;

